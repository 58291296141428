import React, { useContext, useState, useEffect } from "react";
import { Map, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import { jsx } from "@emotion/core";
import { find, valuesIn, every } from "lodash";
import { LocaleContext } from "../context/locale-context";
import useTranslations from "../components/hooks/useTranslations";
import Control from "react-leaflet-control";
import style from "../style/leaflet.css";
import { rhythm, scale } from "../utils/typography";
import useWindowSize from "../components/hooks/useWindowSize";

const Checkbox = ({ label, onCheckboxChange, isSelected }) => {
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();
  return (
    <label
      css={{
        padding: rhythm(0.3),
        display: "block",
        "& input[type=checkbox]": {
          marginRight: rhythm(0.6)
        }
      }}
    >
      <input
        type="checkbox"
        name={label}
        onChange={onCheckboxChange}
        checked={isSelected}
      />
      <div
        css={{
          display: "inline",
          position: "relative",
          paddingLeft: isRTL ? "auto" : rhythm(0.6),
          paddingRight: isRTL ? rhythm(0.7) : "auto",
          fontSize: "1.1rem",
          fontWeight: "bold",
          "&::before": {
            //...scale(1),
            fontSize: "3.2rem",
            position: "absolute",
            left: isRTL ? "auto" : "-0.5rem",
            right: isRTL ? "0" : "auto",
            top: "-1.9rem",
            content: '"•"',
            color: colorMarker(label)
          }
        }}
      >
        {tr(label)}
      </div>
    </label>
  );
};

function colorMarker(alleged_perpetrator) {
  switch (alleged_perpetrator) {
    case "AQAP":
      return "#60323D";
    case "Houthi forces":
      return "#6E6586";
    case "Pro-coalition forces":
      return "#40A3B1";
    case "Saudi-led coalition":
      return "#6DDB9A";
    case "Unknown":
      return "#FDFE70";
    case "تنظيم القاعدة":
      return "#60323D";
    case "قوات الحوثيين":
      return "#6E6586";
    case "القوات الموالية للتحالف":
      return "#40A3B1";
    case "التحالف بقيادة السعودية":
      return "#6DDB9A";
    default:
      return "#FDFE70";
  }
}

function MapFilters({ filterOptions, setFilterOptions }) {
  const locale = useContext(LocaleContext);
  const tr = useTranslations();
  const isRTL = locale === "ar";
  return (
    <div
      css={{
        backgroundColor: "#fff",
        marginLeft: rhythm(1),
        padding: `${rhythm(2)} ${rhythm(2.3)}`,
        borderRadius: "20px",
        boxShadow: "0 2px 4px 0 rgba(101,101,101,0.5)"
      }}
    >
      <h3
        css={{
          margin: 0,
          marginBottom: rhythm(0.8)
        }}
      >
        {tr("Discover Incidents")}
      </h3>
      <Checkbox
        label={tr("Saudi-led coalition")}
        isSelected={filterOptions.suida}
        onCheckboxChange={() =>
          setFilterOptions(prevState => {
            return {
              ...filterOptions,
              suida: !prevState.suida
            };
          })
        }
      />
      <Checkbox
        label={tr("Houthi forces")}
        isSelected={filterOptions.houthis}
        onCheckboxChange={() =>
          setFilterOptions(prevState => {
            return {
              ...filterOptions,
              houthis: !prevState.houthis
            };
          })
        }
      />
      <Checkbox
        label={tr("AQAP")}
        isSelected={filterOptions.aqap}
        onCheckboxChange={() =>
          setFilterOptions(prevState => {
            return {
              ...filterOptions,
              aqap: !prevState.aqap
            };
          })
        }
      />
      <Checkbox
        label={tr("Pro-coalition forces")}
        isSelected={filterOptions.coalition}
        onCheckboxChange={() =>
          setFilterOptions(prevState => {
            return {
              ...filterOptions,
              coalition: !prevState.coalition
            };
          })
        }
      />
      <Checkbox
        label={tr("Unknown")}
        isSelected={filterOptions.unknown}
        onCheckboxChange={() =>
          setFilterOptions(prevState => {
            return {
              ...filterOptions,
              unknown: !prevState.unknown
            };
          })
        }
      />
    </div>
  );
}

function YemeniMap({ JsonData }) {
  const [windowWidthSize, windowHeightSize] = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const [yemeniData, setYemeniData] = useState(JsonData);
  const [filterOptions, setFilterOptions] = useState({
    suida: false,
    houthis: false,
    aqap: false,
    coalition: false,
    unknown: false
  });
  let isAllFiltersOff = true;

  useEffect(() => {
    windowWidthSize < 992 ? setIsMobile(true) : setIsMobile(false);
    handle();
  }, [filterOptions, windowWidthSize]);

  let allegedPerpetrator = [];

  const handle = () => {
    isAllFiltersOff = every(valuesIn(filterOptions), e => e == false);
    if (filterOptions.suida) {
      allegedPerpetrator.push("Saudi-led coalition");
    }
    if (filterOptions.houthis) {
      allegedPerpetrator.push("Houthi forces");
    }
    if (filterOptions.aqap) {
      allegedPerpetrator.push("AQAP");
    }
    if (filterOptions.coalition) {
      allegedPerpetrator.push("Pro-coalition forces");
    }
    if (filterOptions.unknown) {
      allegedPerpetrator.push("Unknown");
    }
    isAllFiltersOff
      ? setYemeniData(JsonData)
      : setYemeniData(
          JsonData.filter(({ alleged_perpetrator }) =>
            allegedPerpetrator.includes(alleged_perpetrator)
          )
        );
    allegedPerpetrator = [];
  };

  const viewPort = {
    lat: 15.5,
    lng: 46,
    zoom: 7
  };

  const position = [viewPort.lat, viewPort.lng];

  return (
    <div>
      {typeof window !== "undefined" ? (
        <Map
          center={position}
          zoom={viewPort.zoom}
          scrollWheelZoom={false}
          className="leaflet-map"
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://tiles.stadiamaps.com/tiles/stamen_toner_lite/{z}/{x}/{y}{r}.png"
          />
          {yemeniData.map(unit => {
            return (
              <Marker
                key={unit.id}
                position={{
                  lat: unit.lat,
                  lng: unit.long
                }}
                riseOnHover={true}
                opacity="0"
              >
                <Popup>
                  <span>
                    {unit.title_ar} - {unit.id}
                  </span>
                </Popup>
                <Circle
                  center={{
                    lat: unit.lat,
                    lng: unit.long
                  }}
                  fill={true}
                  stroke={false}
                  opacity={0}
                  fillColor={colorMarker(unit.alleged_perpetrator)}
                  radius={5000}
                  fillOpacity={0.7}
                />
              </Marker>
            );
          })}
          <Control position="topleft">
            {isMobile || (
              <MapFilters
                JsonData={JsonData}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
              />
            )}
          </Control>
        </Map>
      ) : null}
    </div>
  );
}

export default YemeniMap;

export { colorMarker };

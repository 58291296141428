import React, { useState, useEffect, useContext, Fragment } from "react";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { jsx } from "@emotion/core";
import { find, groupBy, filter, values, map } from "lodash";
import Header, { QuickNav } from "../components/Header";
import useTranslations from "../components/hooks/useTranslations";
import LocalizedLink from "../components/localizedLink";
import { LocaleContext } from "../context/locale-context";
import { rhythm, scale } from "../utils/typography";
import YemeniMap from "../components/YemeniMap";
import DataBlock from "../components/DataBlock";
import JsonData from "../../data/data.json";
import { mq } from "../utils/helper";
import styled from "@emotion/styled";
import useWindowSize from "../components/hooks/useWindowSize";
import SEO from "../components/seo";

const styleObj = {
  p: styled.p({
    color: "black",
    [mq[0]]: {
      // fontSize: "80%"
    }
  }),
  h1: styled.h1({
    [mq[0]]: {
      fontSize: "1.7rem"
    }
  }),
  h2: styled.h2({
    [mq[0]]: {
      fontSize: "1.5rem"
    }
  })
};

const Statistic = ({ number, title, desc }) => {
  const tr = useTranslations();
  return (
    <div
      css={{
        color: "#fff"
      }}
    >
      <h1
        css={{
          ...scale(1.3),
          textShadow: "0 2px 4px rgba(0,0,0,0.5)",
          [mq[0]]: {
            fontSize: "4rem"
          }
        }}
      >
        {number}
      </h1>
      <p
        css={{
          ...scale(0.7),
          textShadow: "0 2px 4px rgba(0,0,0,0.5)",
          fontWeight: "bold",
          width: "80%",
          borderBottom: "2px solid #B32C50"
        }}
      >
        {tr(title)}
      </p>
      <small
        css={{
          fontSize: "1rem"
        }}
      >
        {tr(desc)}
      </small>
    </div>
  );
};

function Text({ data, isRTL }) {
  return (
    <div
      css={{
        maxWidth: rhythm(45),
        margin: `${rhythm(2)} auto`,
        ...scale(1 / 3),
        direction: isRTL ? "rtl" : "ltr",
        "& p": {
          direction: isRTL ? "rtl" : "ltr"
        },
        "& table": {
          direction: isRTL ? "rtl !important" : "ltr",
          "& td, & th": {
            direction: isRTL ? "rtl !important" : "ltr",
            textAlign: isRTL ? "right" : "left"
          }
        },
        [mq[0]]: {
          marginRight: rhythm(1),
          marginLeft: rhythm(1),
          "& div": {
            display: "block !important"
          }
        }
      }}
    >
      <MDXProvider
        components={{
          p: styleObj.p,
          h1: styleObj.h1,
          h2: styleObj.h2
        }}
      >
        <MDXRenderer>{data.body}</MDXRenderer>
      </MDXProvider>
    </div>
  );
}

function TheData({ data, isMobile, isRTL }) {
  const tr = useTranslations();
  const dataSet = [
    {
      key: `${tr("Facebook Posts")}`,
      value: 596,
      color: "#60323D"
    },
    {
      key: `${tr("Tweets")}`,
      value: 526,
      color: "#6E6586"
    },
    {
      key: `${tr("Articles")}`,
      value: 474,
      color: "#40A3B1"
    },
    {
      key: `${tr("Youtube Videos")}`,
      value: 159,
      color: "#6DDB9A"
    }
  ];
  const blocks = dataSet.map(d => (
    <div
      key={d.value}
      css={{
        maxHeight: "100%"
      }}
    >
      <h3
        css={{
          marginTop: 0
        }}
      >
        {d.key}
      </h3>
      <DataBlock data={d} dimensions={{ width: 800, height: 150 }} />
    </div>
  ));
  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(20px, 1fr))",
        gridColumnGap: rhythm(1),
        maxWidth: "90%",
        margin: "0 auto",
        direction: isRTL ? "rtl" : "ltr",
        [mq[1]]: {
          gridTemplateColumns: "1fr"
        }
      }}
    >
      <div
        css={{
          fontSize: "1.5rem",
          overflow: "auto",
          [mq[0]]: {
            marginRight: rhythm(1),
            marginLeft: rhythm(1),
            "& div": {
              display: "block !important"
            }
          }
        }}
      >
        <MDXProvider
          components={{
            p: styleObj.p,
            h1: styleObj.h1,
            h2: styleObj.h2
          }}
        >
          <MDXRenderer>{data.body}</MDXRenderer>
        </MDXProvider>
      </div>
      <div
        css={{
          marginTop: "2.9rem"
        }}
      >
        {isMobile || blocks}
      </div>
    </div>
  );
}

function Statistics({ image, isMobile, isRTL }) {
  return (
    <Fragment>
      <div
        css={{
          height: isMobile ? "70rem" : "70vh",
          backgroundImage: `url(${image.src})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed",
          position: "relative",
          direction: isRTL ? "rtl" : "ltr"
        }}
      >
        <div
          css={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundImage: "url(/assets/backgroundsmallpattern.png)"
          }}
        >
          <div
            css={{
              position: "relative",
              top: "50%",
              transform: "translateY(-50%)",
              display: isMobile ? "block" : "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridColumnGap: rhythm(2),
              maxWidth: "70%",
              margin: "0 auto"
            }}
          >
            <Statistic
              number="98"
              title="Civilians Killed"
              desc="The number of civilians killed as a result of attacks on health care"
            />
            <Statistic
              number="191"
              title="Civilians Injured"
              desc="The number of civilians Injured as a result of attacks on health care"
            />
            <Statistic
              number="23"
              title="Women and Children Killed"
              desc="The number of women and children killed as a result of attacks on health care"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function IncidentsMap({ isRTL }) {
  const tr = useTranslations();
  return (
    <Fragment>
      <div
        css={{
          maxWidth: rhythm(45),
          margin: `${rhythm(2)} auto`
        }}
      >
        <h1
          css={{
            textAlign: isRTL ? "right" : "left"
          }}
        >
          {tr("Discover Incidents")}
        </h1>
      </div>
      <div
        css={{
          direction: isRTL ? "rtl" : "ltr"
        }}
      >
        <YemeniMap JsonData={JsonData} />
      </div>
    </Fragment>
  );
}

function Index({ data }) {
  const [windowWidthSize, windowHeightSize] = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const images = data.allFile.edges.map(u => u.node);
  const partials = data.allMdx.edges.map(p => p.node);
  const getText = textTitle =>
    find(partials, { frontmatter: { name: textTitle } });
  const introText = getText("intro");
  const dataText = getText("data");
  const attacksText = getText("attacks");
  const deliveryText = getText("delivery");
  const victimsTextP1 = getText("victims-one");
  const victimsTextP2 = getText("victims-two");
  const getImg = imgName =>
    find(images, {
      childImageSharp: { fluid: { originalName: `${imgName}.jpg` } }
    });
  const secondImg = getImg("second").childImageSharp.fluid;
  useEffect(() => {
    windowWidthSize < 992 ? setIsMobile(true) : setIsMobile(false);
  }, [windowWidthSize]);
  const locale = useContext(LocaleContext);
  const isRTL = locale == "ar";
  return (
    <div
      css={{
        "& p": {
          direction: isRTL ? "rtl !important" : "ltr"
        }
      }}
    >
      <SEO />
      <Header />
      <Text data={introText} isRTL={isRTL} />
      <TheData data={dataText} isMobile={isMobile} isRTL={isRTL} />
      <Text data={attacksText} isRTL={isRTL} />
      <IncidentsMap isRTL={isRTL} />
      <Text data={deliveryText} isRTL={isRTL} />
      <Text data={victimsTextP1} isRTL={isRTL} />
      <Statistics image={secondImg} isMobile={isMobile} isRTL={isRTL} />
      <Text data={victimsTextP2} isRTL={isRTL} />
    </div>
  );
}

export default Index;

export const pageQuery = graphql`
  query Index($locale: String!) {
    allMdx(
      filter: { frontmatter: { page: { eq: "index" }, lang: { eq: $locale } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            name
          }
          body
        }
      }
    }
    allFile {
      edges {
        node {
          id
          childImageSharp {
            fluid(quality: 75, maxWidth: 1200) {
              src
              ...GatsbyImageSharpFluid
              originalName
              originalImg
            }
          }
        }
      }
    }
  }
`;
